import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-portal',
  templateUrl: './student-portal.component.html',
  styleUrls: ['./student-portal.component.scss']
})
export class StudentPortalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
