import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-portal',
  templateUrl: './management-portal.component.html',
  styleUrls: ['./management-portal.component.scss']
})
export class ManagementPortalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
