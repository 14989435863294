import { TranslateModule } from '@ngx-translate/core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { HeadPanelComponent } from './head-panel/head-panel.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarsComponent } from './navbars/navbars.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    NgxPageScrollModule,
    TranslateModule
  ],
  declarations: [
    NavbarsComponent,
    FooterComponent,
    HeadPanelComponent
  ],
  exports: [
    NavbarsComponent,
    FooterComponent,
    HeadPanelComponent
  ]
})
export class SharedModule { }
