import { ArticlesComponent } from './components/articles/articles.component';
import { TranslateModule } from '@ngx-translate/core';
import { ExamPortalComponent } from './components/module/exam-portal/exam-portal.component';
import { StudentPortalComponent } from './components/module/student-portal/student-portal.component';
import { ManagementPortalComponent } from './components/module/management-portal/management-portal.component';
import { RecommendationsComponent } from './components/recommendations/recommendations.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';
import { AboutComponent } from './components/about/about.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [
    PublicComponent,
    WelcomeComponent,
    ManagementPortalComponent,
    StudentPortalComponent,
    ExamPortalComponent,
    AboutComponent,
    RecommendationsComponent,
    ArticlesComponent,
    SubscribeComponent
  ],
  exports: [
    PublicComponent
  ]
})
export class PublicModule { }
