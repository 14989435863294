import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor(@Inject(DOCUMENT) document) { }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {

    if (window.pageYOffset > 50) {
      ($('.navbar') as any).addClass('fixed-top').addClass('nav-shadow').addClass('bg-light-white');
    } else {
      ($('.navbar') as any).removeClass('fixed-top').removeClass('nav-shadow').removeClass('bg-light-white');
    }
  }

}
